import { Box, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import classNames from 'classnames';
import { TopbarContainer } from '../..';
import { useShopConfig } from '../../../hooks/shopConfig';
import {
  BuilderSection,
  Footer,
  HeroActions,
  IconTreetLogo,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Logo,
  SectionFooterActionV2,
  SectionHeroV2,
} from '../../../components';
import { ABOUT_PAGES_CONTENT_WIDTH, AboutPageTab } from '../aboutPageUtils';
import { useIsMobile } from '../../../hooks/useIsMobile';
import { LANDING_PAGE_SECTION } from '../../../shopConfig/config';
import AboutNavbar from './AboutNavbar';
import { FooterLogo } from '../../../types/shopConfig/shopConfigV2';
import css from '../AboutPage.module.css';
import { useEnabledCustomerExperiences } from '../../../hooks/useEnabledCustomerExperiences';
import TopbarTransparencyContext from '../../../context/TopbarTransparencyContext';
import { useWindowScroll } from '../../../hooks/useWindowScroll';
import { BuilderSections } from '../../../util/builder';

const TRANSPARENT_TOPBAR_SCROLL_THRESHOLD = 50; // in pixels

export const AboutPagesFooterAction: FC = () => {
  const {
    landingPageLayoutOrder,
    shopName,
    css: shopConfigV2Css,
    copy,
    footerLogos,
  } = useShopConfig();
  const isMobile = useIsMobile();
  const { allowMarketplace } = useEnabledCustomerExperiences();

  if (!landingPageLayoutOrder.includes(LANDING_PAGE_SECTION.FOOTER)) return null;

  const invertedLogoStyle = shopConfigV2Css?.invertedLogoStyle;
  const footerSubtitle = copy?.footerSubtitle;

  const shopNameEl = invertedLogoStyle ? (
    <Logo className={css.icon} format="desktop" type="original" style={invertedLogoStyle} />
  ) : (
    <Typography variant="h1" style={{ color: 'white', position: 'relative', fontWeight: 'bold' }}>
      {shopName}
    </Typography>
  );

  const footerLogoTypeToEl = {
    [FooterLogo.Brand]: shopNameEl,
    [FooterLogo.Treet]: <IconTreetLogo color="white" className={css.icon} />,
    [FooterLogo.SuperCircle]: (
      <img className={css.icon} src="/static/logos/supercircle.png" alt="supercircle-logo" />
    ),
  };

  const footerHeaderEl = (
    <Box display="flex" alignItems="center" flexDirection={isMobile ? 'column' : 'row'}>
      {footerLogos.map((logoType: FooterLogo, index: number) => (
        <>
          {index !== 0 && (
            <pre className={classNames({ [css.footerAdditionMobile]: isMobile })}>
              <Typography
                variant="h1"
                style={{
                  color: 'white',
                  position: 'relative',
                  fontWeight: 'bold',
                  lineHeight: 'unset',
                }}
              >
                {`   +   `}
              </Typography>
            </pre>
          )}
          {footerLogoTypeToEl[logoType]}
        </>
      ))}
    </Box>
  );

  const defaultFooterSubtitle = allowMarketplace
    ? `Buy and sell ${shopName} from your peers. Treet is our partner to help manage this amazing peer-to-peer secondhand experience.`
    : 'Treet is our partner to help manage this amazing resale experience.';
  return (
    <SectionFooterActionV2
      headerEl={footerHeaderEl}
      description={footerSubtitle || defaultFooterSubtitle}
      actionEl={<HeroActions />}
    />
  );
};

const SectionHero: FC = () => {
  const { images, builderConfig } = useShopConfig();
  const isMobile = useIsMobile();

  const aboutImageDefaultUrl = images?.aboutImage?.url;
  const aboutImageMobileUrl = images?.aboutImageMobile?.url;
  const aboutImageUrl = (isMobile && aboutImageMobileUrl) || aboutImageDefaultUrl;
  const aboutPageHeroSectionId = builderConfig?.sections?.[BuilderSections.AboutPageHeroContent];

  return (
    <>
      {!aboutPageHeroSectionId && (
        <SectionHeroV2 imageUrl={aboutImageUrl} actionEl={<HeroActions />} />
      )}
      {aboutPageHeroSectionId && (
        <BuilderSection
          sectionType={BuilderSections.AboutPageHeroContent}
          sectionId={aboutPageHeroSectionId}
        />
      )}
    </>
  );
};

interface NavbarProps {
  selectedTabName: string;
  onTabSelected: (tab: AboutPageTab) => void;
}

const Navbar: FC<NavbarProps> = (props) => {
  const { selectedTabName, onTabSelected } = props;

  const { builderConfig } = useShopConfig();

  const aboutPageNavbarSectionId =
    builderConfig?.sections?.[BuilderSections.AboutPageNavbarContent];

  return (
    <>
      {!aboutPageNavbarSectionId && (
        <AboutNavbar onTabSelected={onTabSelected} selectedTabName={selectedTabName} />
      )}
      {aboutPageNavbarSectionId && (
        <BuilderSection
          sectionType={BuilderSections.AboutPageNavbarContent}
          sectionId={aboutPageNavbarSectionId}
          sectionData={{ selectedTabName }}
          sectionContext={{ onTabSelected }}
        />
      )}
    </>
  );
};

interface AboutPagesWrapperProps {
  selectedTabName: string;
  onTabSelected: (tab: AboutPageTab) => void;
  allowFullBleed?: boolean;
}

const AboutPagesWrapper: FC<AboutPagesWrapperProps> = (props) => {
  const { selectedTabName, children, allowFullBleed = false, onTabSelected } = props;

  const { css: brandCss } = useShopConfig();
  const scroll = useWindowScroll();

  const isFullBleedTopbar = brandCss?.bleedTopbarIntoHeroImage ?? false;
  const isTopbarTransparent = isFullBleedTopbar && scroll.y < TRANSPARENT_TOPBAR_SCROLL_THRESHOLD;
  const topbarClasses = classNames(css.topbar, {
    [css.topbarAboveHero]: isFullBleedTopbar,
  });

  return (
    <LayoutSingleColumn>
      <LayoutWrapperTopbar className={topbarClasses}>
        <TopbarTransparencyContext.Provider value={isTopbarTransparent}>
          <TopbarContainer />
        </TopbarTransparencyContext.Provider>
      </LayoutWrapperTopbar>
      <LayoutWrapperMain>
        <SectionHero />
        <Navbar onTabSelected={onTabSelected} selectedTabName={selectedTabName} />
        <Box
          display="flex"
          flexDirection="column"
          alignContent="flex-start"
          alignItems="center"
          justifyContent="center"
          bgcolor={brandCss?.backgroundColor}
          // Need this so that this box rerenders when the tab changes and the padding can be rerendered
          key={selectedTabName}
          {...(!allowFullBleed && { px: { xs: 3, sm: 5, md: 10 }, py: { xs: 5, md: 8 } })}
        >
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            {...(!allowFullBleed && { maxWidth: ABOUT_PAGES_CONTENT_WIDTH })}
          >
            {children}
          </Box>
        </Box>
        <AboutPagesFooterAction />
      </LayoutWrapperMain>
      <LayoutWrapperFooter>
        <Footer />
      </LayoutWrapperFooter>
    </LayoutSingleColumn>
  );
};
export default AboutPagesWrapper;
