import { Box } from '@material-ui/core';
import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  BuyerFAQ,
  InformationModule,
  NamedLink,
  RecentArrivalsCarousel,
} from '../../../components';
import { ContentDirection } from '../../../components/InformationModule/InformationModule';
import { MIN_CAROUSEL_LISTINGS } from '../../../components/ListingItemsCarousel/ListingItemsCarousel';
import { useShopConfigV2 } from '../../../hooks/shopConfig';
import { useIsShopSideLaunched } from '../../../hooks/useIsShopSideLaunched';
import { getBuyerContentConfig } from '../aboutPageUtils';
import Content, { ContentMbSize } from './Content';
import BuyerSellerInfoTitle from './BuyerSellerInfoTitle';
import {
  fetchRecentListings,
  RecentArrivalsCarouselState,
} from '../../../ducks/recentArrivalsCarousel.duck';

const BuyerInformationModule: FC = () => {
  const { buyerSellerInfoOverrides, cashPayoutPercentage, creditPayoutPercentage, images } =
    useShopConfigV2();
  const customIcons = images?.howItWorksCustomImages;

  const content = getBuyerContentConfig(
    creditPayoutPercentage,
    cashPayoutPercentage,
    customIcons,
    buyerSellerInfoOverrides
  );
  return (
    <>
      <BuyerSellerInfoTitle>Buying on Treet</BuyerSellerInfoTitle>
      <Content mbSize={ContentMbSize.Large}>
        <InformationModule content={content} direction={ContentDirection.Horizontal} />
      </Content>
    </>
  );
};

const BuyerFAQs: FC = () => (
  <>
    <BuyerSellerInfoTitle>Buyer FAQ’s</BuyerSellerInfoTitle>
    <Content mbSize={ContentMbSize.Large}>
      <BuyerFAQ />
    </Content>
  </>
);

const RecentlyListedListings: FC = () => {
  const { recentListings } = useSelector<any>(
    (state) => state.recentArrivalsCarousel
  ) as RecentArrivalsCarouselState;
  const isShopSideLaunched = useIsShopSideLaunched();

  if (!isShopSideLaunched || recentListings.length < MIN_CAROUSEL_LISTINGS) return null;

  return (
    // If styles for BuyerSellerInfoTitle are updated, also update the title style in
    // RecentArrivalsCarousel
    <Content mbSize={ContentMbSize.Large}>
      <RecentArrivalsCarousel />
    </Content>
  );
};

const BuyerInfoTab: FC = () => {
  const dispatch = useDispatch();
  const isShopSideLaunched = useIsShopSideLaunched();

  useEffect(() => {
    dispatch(fetchRecentListings());
  }, []);

  return (
    <>
      <BuyerInformationModule />
      <BuyerFAQs />
      <RecentlyListedListings />
      {isShopSideLaunched && (
        <Box display={{ md: 'flex' }} justifyContent="center" mb={5}>
          <NamedLink name="LandingPage" to={{ search: '' }} style={{ textDecoration: 'none' }}>
            <Button>Shop All</Button>
          </NamedLink>
        </Box>
      )}
    </>
  );
};
export default BuyerInfoTab;
